import React, { useState, useEffect } from 'react';
import '../sass/App.scss';
import Game from '../components/Game'

function App() {
  const [games, setGames] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const response = await fetch(
        "https://api-flashscore.tiagosantos.xyz/games/live/by_league"
      );
      let data = await response.json();
      if(!Array.isArray(data)) data = Object.values(data);
      setGames(data);
    };
    getData();
    setInterval(function(){ getData(); }, 10000);
  }, []);

  return (
    <div className="container">
      <div className="text-center">
        <img src="./logo.png" className="logo-site"/>
      </div>
  
      {games.map((league, league_idx) => {
        return (
          <div key={league_idx}>
            <div className="d-flex flex-column align-items-center justify-content-center">
            <h3 className="league-name mt-4">{league.name}</h3>
            </div>
            {league.league_games.map((game, game_idx) => {
              return <Game key={game_idx} data={game} />
            })}
          </div>
        )
      })}
      
    </div>
  );
}

export default App;
